import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "./hotsummer.css"
import {pagewiseads_url } from "../../globalURL";
import axios from 'axios';
const Hotsummer = (props) => {
  var mymenuads = props.mymenuads;
  var chnl_id = props.channel_id;
  var page = props.page;
  
  useEffect(() => {
    loadPageAds(chnl_id);
  }, [chnl_id])
  const [pageFData, setPageFData] = useState([]);
  const [pageSData, setPageSData] = useState([]);
  const [pageTData, setPageTData] = useState([]);
  const loadPageAds = async (chnl_id) => {
    //  const responseBody = { menu_cat_name: menu_cat_id, channel_id: chnl_id };
    const responseBody = { channel_id: chnl_id,page:page,position:1 };
      await axios
        .post(pagewiseads_url, JSON.stringify(responseBody), {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          var resp_data = res.data;
          if (resp_data.status === 'true' && resp_data != null) {
            var resp_datalist = resp_data.data;
            setPageFData(resp_datalist);
          }
  
        })
        .catch((error) => {
          //setIsLoading(false);
          console.log(error);
        });


        const responseBodys = { channel_id: chnl_id,page:page,position:2 };
        await axios
          .post(pagewiseads_url, JSON.stringify(responseBodys), {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            var resp_data = res.data;
            if (resp_data.status === 'true' && resp_data != null) {
              var resp_datalist = resp_data.data;
              setPageSData(resp_datalist);
            }
    
          })
          .catch((error) => {
            //setIsLoading(false);
            console.log(error);
          });


          const responseBodyT = { channel_id: chnl_id,page:page,position:3 };
          await axios
            .post(pagewiseads_url, JSON.stringify(responseBodyT), {
              headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
              var resp_data = res.data;
              if (resp_data.status === 'true' && resp_data != null) {
                var resp_datalist = resp_data.data;
                setPageTData(resp_datalist);
              }
      
            })
            .catch((error) => {
              //setIsLoading(false);
              console.log(error);
            });

    };
   // alert(JSON.stringify(pageFData));
  return (
    <div className='sum'>
         {pageFData && pageFData.map(function (ads, videoindex) {
    
          if(ads.ad_type == "Imageupload"){
           return (
          <Link to={ads.redirection_link}  target='_blank' className='mb-3 d-block'>
            <img src={ads.image_url} alt=""/> 
          </Link>
           )
          }
      })}
       {pageSData && pageSData.map(function (ads, videoindex) {
    
    if(ads.ad_type == "Imageupload"){
     return (
      <div className='d-none d-lg-block d-xl-block'><Link to={ads.redirection_link}  target='_blank' className='mb-3 '>
      <img src={ads.image_url} alt=""/> 
    </Link></div>
    
     )
    }
})}
   {pageTData && pageTData.map(function (ads, videoindex) {
    
    if(ads.ad_type == "Imageupload"){
     return (
    <Link to={ads.redirection_link}  target='_blank' className='mb-3 d-block'>
      <img src={ads.image_url} alt=""/> 
    </Link>
     )
    }
})}

    </div>
  )
}

export default Hotsummer