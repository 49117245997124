import React , { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { headeradurl,tajanewsads_url } from "../../../globalURL";
//import { useGooglePublisherTag } from 'react-google-publisher-tag';


function NewsAds() {

  useEffect(() => {
    getHeaderAd();
    const script = document.createElement('script');
    script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
    script.async = true;

    script.onload = () => {
      window.googletag = window.googletag || {};
      window.googletag.cmd = window.googletag.cmd || [];

      window.googletag.cmd.push(function() {
        window.googletag.display('div-gpt-ad-1688636842153-0');
      });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [])
  const [ourAds, ourSetAds] = useState([])
  const getHeaderAd = async () => {
    const responseBody = { };
    await axios
      .post(tajanewsads_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourSetAds(resp_datalist);
        }
      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  if(ourAds.length > 0){
    if(ourAds[0].type == "Imageupload"){
      return (

        <>
            <Link target="_blank" to={ourAds[0].redirection_link} ><img src={ourAds[0].image_url}  className='w-100 lates-add' /></Link>
        </>
      )
  }else{
    
    return (
<div id="div-gpt-ad-1688636842153-0" style={{ minWidth: '468px', minHeight: '60px' }} />    );
    
  }
}
}

export default NewsAds