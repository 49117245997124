import React, { useEffect, useState } from 'react'
import { FaChevronLeft, FaChevronRight, } from 'react-icons/fa'
import { Link, useParams } from 'react-router-dom';
import { pagewiseads_url,getchannelbyseoname, showsvideoslist_url, getloadheadingpage_url,getallheadings_url, getTagsByShows_url, getchannelvideoseourl, getloadheading_url, getTagMenuidurl, getshowseourl, getloadmenuadsurl, menuwiseshowadsurl,getrelatedvideos_url } from "./globalURL";
import axios from 'axios';
import Slider from "react-slick";
import { Button, Col, Container, Row } from 'react-bootstrap'
import Feature from './Components/videoblock/Components/Feature';
import AddBanner from './Components/ShowView/Components/AddBanners/AddBanner';

import PlayIcon from './Components/PlayIcon';
import { ControlBar, Player } from 'video-react';
import Videotabs from './Components/Videotabs';
import Episodes from './Components/Episodes';

const WeekScheduleViewVideo = () => {

  const params = useParams();
  var channel_seo_name = params.channelseo;
  var showtitle = params.showtitle;
  var videtitle = params.videtitle;
  var headingtitle = params.headingtitle;
  //var showviewid = 0;
//alert(showtitle+"showtitle");
//alert(headingtitle+"headingtitle");
  const [ourheadinginfo, setOurheadinginfo] = useState([]);
  var sidetagid=0;
  const [ourVideos, setOurVideos] = useState([]);
  const [relatedVideos, setRelatedVideos] = useState([]);
  const [storechannel, setStorechannel] = useState([]);
  const [ourhometags, setOurHeadings] = useState([])
  const [menuwiseAdsfirst, setMenuwiseAdsfirst] = useState([]);
  const [menuwiseAdslast, setMenuwiseAdslast] = useState([]);
  const [menushowwiseAds, setmenushowwiseAds] = useState([]);
  const [ourrelatedvideos, ourSetRelatedVideos] = useState([])
  const [isSetShowid, isOutSetShowid] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getChannelInfo(channel_seo_name, videtitle, showtitle,headingtitle);

  }, [channel_seo_name, videtitle, showtitle,headingtitle])

  const getChannelInfo = async (channel_seo_name, videtitle, showtitle,headingtitle) => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setStorechannel(resp_datalist);
          if(resp_datalist.length > 0){
            loadMenuhomewiseAds(resp_datalist[0].chnl_id);
            loadMenuhomewiseAdsLast(resp_datalist[0].chnl_id);
            getChannelVideoId(resp_datalist[0].chnl_id, videtitle);
            getShowInfo(resp_datalist[0].chnl_id, showtitle);
            getHeadingInfo(resp_datalist[0].chnl_id, showtitle); // headingtitle = showtitle
          }
   
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }

  const getHeadingInfo = async (chnl_id, headingtitle) => {
    const responseBody = {channel_id:chnl_id, seo_tag_title: headingtitle };
    await axios
      .post(getTagMenuidurl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
       
          if(resp_datalist.length > 0){
            fetchRelatedTagVideos(resp_datalist[0].tag_id, chnl_id)

          }
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const getShowInfo = async (chnl_id, showseo) => {
    const responseBody = { showseoname: showseo };
    await axios
      .post(getshowseourl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          if(resp_datalist.length > 0){
            isOutSetShowid(resp_datalist[0].show_id);
            fetchRelatedVideos(resp_datalist[0].show_id, chnl_id)

          }
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const fetchRelatedTagVideos = async (tag_id, chnl_id) => {
    // const session_chnl_id = localStorage.getItem('session_chnl_id');
    const responseBody = { channel_id: chnl_id, tag_id: tag_id };
    await axios
      .post(getrelatedvideos_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourSetRelatedVideos(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };


  const fetchRelatedVideos = async (showid, chnl_id) => {
    // const session_chnl_id = localStorage.getItem('session_chnl_id');
    const responseBody = { channel_id: chnl_id, show_id: showid };
    await axios
      .post(showsvideoslist_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourSetRelatedVideos(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };

  const getChannelVideoId = async (channel_id, videtitle) => {
    const responseBody = { channel_id: channel_id, video_seo_title: videtitle };
    await axios
      .post(getchannelvideoseourl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          fetchShowVideos(resp_datalist[0].sv_id, channel_id);
         
          loadMenuShowwiseAds(channel_id, resp_datalist[0].menu_cat_id);
          loadHeadings(resp_datalist[0].menu_cat_id, channel_id);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }

  const fetchShowVideos = async (video_id, channel_id) => {
    const responseBody = { video_id: video_id };
    await axios
      .post(showsvideoslist_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          var tagslist = resp_datalist[0].sv_hometags;
          //  console.log(resp_datalist);
          //  funTagsWise(tagslist,resp_datalist[0].sv_show_id,resp_datalist[0].sv_id,channel_id);
          setOurVideos(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };

  // const funTagsWise = async (tagslist,sv_show_id,sv_id,channel_id) => {
  //   const responseBody = { tags_str: tagslist,sv_show_id:sv_show_id,sv_id:sv_id,channel_id:channel_id};
  //   await axios
  //     .post(getTagsByShows_url, JSON.stringify(responseBody), {
  //       headers: { "Content-Type": "application/json" },
  //     })
  //     .then((res) => {
  //       //console.log(res.data);
  //       var resp_data = res.data;
  //       if (resp_data.status === 'true' && resp_data != null) {
  //         var resp_datalist = resp_data.data;
  //         setRelatedVideos(resp_datalist);
  //       }

  //     })
  //     .catch((error) => {
  //       //setIsLoading(false);
  //       console.log(error);
  //     });
  // };


  const loadHeadings = async (menu_cat_id, chnl_id) => {
    const responseBody = { tag_menu_id: "", pageid: 3, channel_id: chnl_id };
    await axios
      .post(getloadheadingpage_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setOurHeadings(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
  const loadMenuhomewiseAds = async (chnl_id) => {
   // const responseBody = { menu_cat_name: menu_cat_id, channel_id: chnl_id, position: position };
   const responseBody = { channel_id: chnl_id,page:3,position:1 };
   //alert(JSON.stringify(responseBody));

   await axios
      .post(pagewiseads_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setMenuwiseAdsfirst(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
 //  alert(JSON.stringify(menuwiseAdsfirst));

  const loadMenuhomewiseAdsLast = async (chnl_id) => {
   // const responseBody = { menu_cat_name: menu_cat_id, channel_id: chnl_id, position: position };
   const responseBody = { channel_id: chnl_id,page:3,position:2 };
   await axios
      .post(pagewiseads_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setMenuwiseAdslast(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };

  const loadMenuShowwiseAds = async (chnl_id, menu_cat_id) => {
   // const responseBody = { tag_menu_id: menu_cat_id, tag_channel_id: chnl_id };
    const responseBody = { tag_channel_id: chnl_id, page_id: 2 };
    await axios
      .post(menuwiseshowadsurl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          if(resp_datalist.length > 0){
            var ourshows = resp_datalist[0].ourShows;
            setmenushowwiseAds(ourshows);
            setOurheadinginfo(resp_datalist);
          }
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
  //console.log(ourhometags);

  //design related 
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}

      ><div className='dskfmdskf'>

          <FaChevronRight className='sfdsf' /></div> </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><div className='fdsf'>
          < FaChevronLeft />
        </div>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    delay: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]


  };
  var settingsvideo = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    delay: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      }
    ]


  };
  if(ourheadinginfo.length > 0 ){
    sidetagid = ourheadinginfo[0].tag_id;
  }

//alert(JSON.stringify(ourVideos[0].sv_redirect_link));
//console.log(ourrelatedvideos);
  //end design related
  //console.log(ourVideos);

  if (ourVideos.length > 0) {

 // alert(ourVideos[0].video_desc.length);
   if(ourVideos[0].video_desc == ""){
    ourVideos[0].video_desc = ourVideos[0].show_desc;
   }
  var htmlString = ourVideos[0].video_desc;
   // alert(ourVideos[0].show_desc)
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
    const maxCharacters = 500;
    let  truncatedText = "";
    let  showToggle = 0;
    if(htmlString.length > 0){

      truncatedText = isExpanded ? htmlString : htmlString.slice(0, maxCharacters);
      showToggle = htmlString.length > maxCharacters; 
    }
    ///alert(JSON.stringify(ourVideos));
    return (
      <>
        <div className='addban' style={{ background: "#f2f2f2" }}>
          <Container fluid className='pad-left-80 pad-right-80'>
            

            <Row>
              <Col lg={9}>

                <div className='backcolo ffadsfds'>
                  <div className='fdsfsdf'>
               

                    <div style={{ width: '100%', height: '0', paddingBottom: '56.25%', position: 'relative' }} className='video-container'>
                      {(ourVideos[0].video_type == "Youtube") ? <iframe
                       width="100%" height=""
                        src={`https://www.youtube.com/embed/${ourVideos[0].sv_youtube_link}`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe> :
                        <div className='backcolo'>
                          <div className='videod'>
                            <Player  >
                              <source src={ourVideos[0].normalvideolink} />
                              <ControlBar autoHide={true} />


                            </Player>
                          </div>
                        </div>


                      }




                    </div>

                  </div>

                    {/* <Videotabs/> */}

                  <Row className="sara">
                    <Col lg={12}>



                      <h1 className='ali' style={{ padding: '20px 0 0 0' }}>{ourVideos[0].sv_title} </h1>
                      
                      <Row className="sara">
                    <Col lg={7} md={6} sm={6} xs={12}>



                                <div class="showtime">

                              <p className='shiw'> TV SHOW</p>
                              <span>{ourVideos[0].tv_show_timings}</span>

                              </div>

                    </Col>
                    <Col lg={5} md={6} sm={6} xs={12}>
                    {ourVideos[0].sv_redirect_link !== "" && (
  <div className='watch'>
    <div className='watch-in'>
      <Row className='edio'>
        <Col lg={5} md={6} sm={6} xs={6}>
          <p>
            Watch Full <br />
            <span>Content on</span>
          </p>
        </Col>
        <Col lg={7} md={6} sm={6} xs={6}>
          <Row>
            <Col lg={4} md={4} sm={4} xs={6} className="telugu">
              <div className='etv-telugu'>
                <img src="../../../assets/images/etv-win.png" width="30" alt="ETV Telugu" />
              </div>
            </Col>
            <Col lg={8} md={8} sm={8} xs={6} className="telugu">
              <a href={ourVideos[0].sv_redirect_link} target='_blank' rel='noopener noreferrer' className="clic">
                Click Here
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </div>
)}

                    </Col>
                  </Row>
                    </Col>

                  </Row>
                  {ourVideos[0].video_desc.length > 0 &&   <div className='backcolo-in celebri'>
                        <div className='des'>
                      
                        {/* <h4>About</h4> */}
                        <p style={{ margin: "0px" }}>
                        {isExpanded ? (
  <div dangerouslySetInnerHTML={{ __html: htmlString }} />
) : (
  <div dangerouslySetInnerHTML={{ __html: truncatedText + (showToggle ? "<b>...</b>" : "") }} />
)}
                        {/* {showToggle && (
                        <button onClick={toggleExpand} className='syle-color' >
                        {isExpanded ? 'Read Less' : 'Read More'}
                        </button>
                        )} */}

{showToggle && (
        <>
          <button onClick={toggleExpand} className='style-color'>
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
        </>
      )}

                        </p>

                        </div>
                      </div>
                    }
                 

                </div>
                 {/* <Episodes/> */}
                 <div className='padin-t-b-10 d-block d-lg-none d-xl-none' >
  <div className='wid-500 text-center mobile-resp'>
    <Link to="#">
      <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/1698379984KRISHNA_970x90_A.jpg" alt='' className='max-wid-100' height='100' />
    </Link>
  </div>
</div>
                {isSetShowid>0 && ourrelatedvideos.length > 0 && ourrelatedvideos[0].sv_id != ourVideos[0].sv_id && (

                  <div >

                    <>
                      <div class="row" ><div class="col-lg-6 col-md-6 col-9"><h2 class="head ">Related Videos</h2></div><div class="text-end col-lg-6 col-md-6 col-3"></div></div>


                      <div className='feature-main'>
                        <div className='feature'>
                          <div className='fea-contact '>
                            <Slider {...settings}>



                              {ourrelatedvideos &&
                                ourrelatedvideos.map(function (videolist, videoindex) {
                                  
                                  let tosuburl = "/video/" + videolist.channel_seo_url + "/" + videolist.show_name_seo_url + "/" + videolist.sv_title_seo;
                                  if (videolist.sv_id != ourVideos[0].sv_id) {
                                    return (

                                      <div key={videoindex} >
                                        <div className='carousel-card extraicon'>
                                          <Link to={tosuburl} >
                                            <div className='extra'>
                                              {/* <img src={videolist.image_path} alt='' /> */}
                                              {videolist.custom_youtube_type === 'Youtube' ? (
                                          <img src={videolist.medium_img} alt='' />
                                          ) : (
                                            <img src={videolist.image_path} alt='' />
                                          )}
                                              <PlayIcon />
                                            </div>


                                            <h4>{videolist.sv_title}</h4>
                                          </Link>

                                        </div>
                                      </div>

                                    );
                                  }

                                })}
                            </Slider>

                          </div>

                        </div>
                      </div>


                    </>
                  </div>
                )}
   {isSetShowid == 0 && ourrelatedvideos.length > 0  && (
               //   let tourl = "/heading/" + list.channel_seo_url + "/" + list.seo_tag_title;

                  <div >


                    <>
                      {/* <div class="row" ><div class="col-lg-6 col-md-6 col-9"><h2 class="head">Related {ourrelatedvideos[0].seo_tag_title } </h2></div><div class="text-end col-lg-6 col-md-6 col-3"></div></div> */}

                      <div class="row" >
                              <div class="col-lg-6 col-md-6 col-9">
                              <h2 class="head" style={{display:"inline-block"}}>{ourrelatedvideos[0].seo_tag_title }  </h2>
                              <div className='styl-count'>{ourrelatedvideos.length}</div>
                              </div>
                              <div class="text-end col-lg-6 col-md-6 col-3"><Link class="more" to={"/heading/" + ourrelatedvideos[0].channel_seo_url + "/" + ourrelatedvideos[0].seo_tag_title}>More +</Link>
                              </div>
                            </div>
                      <div className='feature-main'>
                        <div className='feature'>
                          <div className='fea-contact '>
                            <Slider {...settings}>



                              {ourrelatedvideos &&
                                ourrelatedvideos.map(function (videolist, videoindex) {
                                  
                                  let tosuburl = "/video/" + videolist.channel_seo_url + "/" + videolist.seo_tag_title + "/" + videolist.sv_title_seo;
                                  if (videolist.sv_id != ourVideos[0].sv_id) {
                                    return (

                                      <div key={videoindex} >
                                        <div className='carousel-card extraicon'>
                                          <Link to={tosuburl} >
                                            <div className='extra'>
                                              {/* <img src={videolist.image_path} alt='' /> */}
                                              {videolist.custom_youtube_type === 'Youtube' ? (
                                          <img src={videolist.medium_img} alt='' />
                                          ) : (
                                            <img src={videolist.image_path} alt='' />
                                          )}
                                              <PlayIcon />
                                            </div>


                                            <h4>{videolist.sv_title}</h4>
                                          </Link>

                                        </div>
                                      </div>

                                    );
                                  }

                                })}
                            </Slider>

                          </div>

                        </div>
                      </div>


                    </>
                  </div>
                )}
             

                {ourhometags &&
                  ourhometags.map(function (list, index) {
                    const tourl = "/heading/" + list.channel_seo_url + "/" + list.seo_tag_title;
                    if (list.count_videos > 0) {
                      if(sidetagid != list.tag_id){
                      if (list.tag_type == "show") {
                        return (

                          <div className='margin-b'>


                            <>
                              <div class="row"><div class="col-lg-6 col-md-6 col-9"><h2 class="head ">{list.tag_title}</h2></div><div class="text-end col-lg-6 col-md-6 col-3"><Link class="more" to={tourl} >More +</Link></div></div>


                              <div className='feature-main '>
                                <div className='featur' >
                                  <div className='fea-contact fea-arrow'>
                                    {/* <Slider {...settings} > */}

                                    <Slider
                                      {...(list.layout_type === "horizontal" ? settings : settingsvideo)}
                                    >


                                      {list.getVideosList &&
                                        list.getVideosList.map(function (videolist, videoindex) {
                                          //  let suburl = "/"+videolist.channel_seo_url+"/"+list.menu_cat_name+"/"+videolist.show_name_seo_url;
                                          let suburl = "/" + videolist.channel_seo_url + "/showview/" + videolist.show_name_seo_url;

                                          return (<div >
                                            <div className='carousel-card extraicon'>
                                              <Link to={suburl}>
                                                {list.layout_type === "horizontal" ? (
                                                  <img src={videolist.show_image} alt='' />
                                                ) : (
                                                  <img src={videolist.show_image2} alt='' />
                                                )}

                                              </Link>


                                            </div>
                                          </div>)
                                        })}

                                    </Slider>
                                  </div>
                                </div>
                              </div>


                            </>
                          </div>

                        );
                      } else {
                        return (

                          <div key={list.tag_id}>

                            <>
                              {/* <div class="row" ><div class="col-lg-6 col-md-6 col-9"><h2 class="head">{list.tag_title}</h2></div><div class="text-end col-lg-6 col-md-6 col-3"><Link class="more" to={tourl}>More +</Link></div></div> */}

                            <div class="row" >
                              <div class="col-lg-6 col-md-6 col-9">
                              <h2 class="head" style={{display:"inline-block"}}>{list.tag_title}  </h2>
                              <div className='styl-count'>{list.getVideosList.length}</div>
                              </div>
                              <div class="text-end col-lg-6 col-md-6 col-3"><Link class="more" to={tourl}>More +</Link>
                              </div>
                            </div>
                              <div className='feature-main'>
                                <div className='feature bg-color'>
                                  <div className='fea-contact '>
                                    {/* <Slider {...settingsvideo}> */}
                                    <Slider
                                      {...(list.layout_type === "horizontal" ? settings : settingsvideo)}
                                    >



                                      {list.getVideosList &&
                                        list.getVideosList.map(function (videolist, videoindex) {
                                          //  let tosuburl = "/video/"+videolist.channel_seo_url +"/"+ videolist.seo_tag_title+"/"+ videolist.sv_title_seo;
                                          let tosuburl = "/video/" + videolist.channel_seo_url + "/" + videolist.seo_tag_title + "/" + videolist.sv_title_seo;

                                          return (

                                            <div key={videoindex} >
                                              <div className='carousel-card extraicon'>
                                                <Link to={tosuburl} >
                                                  <div className='extra'>

                                                    {/* {list.layout_type === "horizontal" ? (
                                                      <img src={videolist.image_path} alt='' />
                                                    ) : (
                                                      <img src={videolist.vertical_image} alt='' />
                                                    )} */}
                                                    {videolist.custom_youtube_type === 'Youtube' ? (
                                          <img src={videolist.medium_img} alt='' />
                                          ) : (
                                          list.layout_type === 'horizontal' ? (
                                          <img src={videolist.image_path} alt='' />
                                          ) : (
                                          <img src={videolist.vertical_image} alt='' />
                                          )
                                          )}
                                                    <PlayIcon />
                                                  </div>
                                                  <h4>{videolist.sv_title}</h4>
                                                </Link>

                                              </div>
                                            </div>

                                          );

                                        })}
                                    </Slider>

                                  </div>

                                </div>
                              </div>


                            </>
                          </div>

                        );

                      }
                    }
                    }

                  })}


              </Col>
              <Col lg={3}>
                <div>

                  {/* <AddBanner firstads={menuwiseAdsfirst} lastads={menuwiseAdslast} secondads={menushowwiseAds} /> */}
                  <AddBanner firstads={menuwiseAdsfirst} ourheadinginfo = {ourheadinginfo} lastads={menuwiseAdslast} secondads={menushowwiseAds} />
  
                </div>
              </Col>
            </Row>

          </Container>

        </div>

      </>
    )
  }
}

export default WeekScheduleViewVideo