import React , { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./footerbottom.css"
import { getfootermenu,getchannelbyseoname } from "../../../globalURL";
import axios from 'axios';
import { useParams } from "react-router-dom";
const FooterBottom = () => {
  const currentYear = new Date().getFullYear();

  const params = useParams();
  var channel_seo_name = params.channelseo;
  if(channel_seo_name == undefined){
    channel_seo_name = "etv-telugu";
  }
  const [storeFooterMenu, storeSetFooterMenu] = useState([]);
  const [ourChannelinfo, ourSetChannelinfo] = useState([])
  useEffect(() => {
    getChannelInfo(channel_seo_name)

    //getFooterMenu();
  }, [channel_seo_name])
  const getChannelInfo = async (channel_seo_name) => {
    if(channel_seo_name == undefined){ channel_seo_name = "etv-telugu";}
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          if(resp_datalist.length > 0){
            getFooterMenu(resp_datalist[0].chnl_id);

          }
          ourSetChannelinfo(resp_datalist);
        
        //  
        }else{
        
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const getFooterMenu = async (channel_id) => {
    const responseBody = {channel_id:channel_id};
    await axios
      .post(getfootermenu, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
      
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          storeSetFooterMenu(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  return (
    <div className='footer-botom'>
   <Container fluid className='pad-left-80 pad-right-80 techink'>
    
      <ul>
        {storeFooterMenu &&
        storeFooterMenu.map(function (list, index) {
          let tosuburl = '';
          if(list.type == "contact"){
            tosuburl = "/"+channel_seo_name+"/footer/contact/" + list.seo_link;
          }else if(list.type == "feedback"){
            tosuburl = "/"+channel_seo_name+"/footer/feedback/" + list.seo_link;
          }else if(list.type == "career"){
            tosuburl = "/"+channel_seo_name+"/footer/career/" + list.seo_link;
          }else if(list.type == "normal"){
            tosuburl = "/"+channel_seo_name+"/footer/" + list.seo_link;
          }
          
         
          return(
        <li><Link to={tosuburl}>{list.name}</Link></li>


          )
        })}
        {/* <li><Link to="/Complaint">Complaint</Link></li> */}
        {/* <li><Link to="/Careers">Career</Link></li> */}
      </ul>
      <p className='enadu'>© Eenadu Television Pvt. Ltd. {currentYear}. All Rights Reserved</p>
      <div className='footer-fixed-add'>
            <Link target="_blank" to="#" ><img src="https://etvcoin.s3.ap-south-1.amazonaws.com/uploads/ads1711000574TULASI-VANAM_970x90.jpg"  className='w-100' /></Link>
        </div>
   </Container>
    </div>
  )
}

export default FooterBottom