import React from 'react'
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick"; 
import "./AddBannerBottom.css"

const AddBannerBottom = (props) => {
  const bottombannerdata = props.bottombanner;
      
     
  return (
    <div className='sum shope d-none d-lg-block d-xl-block'>
    {/* {bottombannerdata && bottombannerdata.map(function (ads, videoindex) {
      return(
      <div>
        <div className='schedule-car'>
        <Link to="" className='mb-3 d-block'>
        <img src={ads.image_url} alt=""/> 
      </Link>
        </div>
      </div>
  )
})}
   */}
 {bottombannerdata && bottombannerdata.map(function (ads, videoindex) {
         if(ads.ad_type == "Imageupload"){

     return(
      <div>
        <div className='schedule-car'>
        <Link to={ads.redirection_link} target='_blank' className='mb-3 d-block'>
        <img src={ads.image_url} alt=""/> 
      </Link>
        </div>
      </div>
      )
     }
    })}
      
      
 {/* <div>
        <div className='schedule-car'>
        <Link target='_blank' to="https://www.etvwin.com/win-exclusive/mr-nagabhushanam" className='mb-3 d-block'>
        <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/ads1697456642WhatsApp-Image-2023-10-16-at-17_07_52-(1).jpeg" alt=""/> 
      </Link>
        </div>
      </div> */}
   
    
    </div>
  )
}
export default AddBannerBottom