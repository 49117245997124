import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./videoblockmain.css"

import Slider from "react-slick";
import { Link, useLocation, useParams } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight, } from 'react-icons/fa'
import Hotsummer from './Hotsummer';
import PlayIcon from '../PlayIcon';
import {pagewiseads_url, gettagshome_url, getloadheading_url, getloadheadingpage_url, getchannels_list_url, getchannelbyseoname, getmenudataseourl, getloadmenuadsurl } from "../../globalURL";
import axios from 'axios';

const Videoblockmain = () => {



  const paramss = useParams();
  var channel_seo_name = paramss.channelseo;
  var menuname = "Home"; 
  const [ourhometags, setOurHeadings] = useState([])
  const [ourchannelinfo, ourchannelInfoList] = useState([]);
  const [storechannel, setStorechannel] = useState([]);
  const [storeMenu, setStoreMenu] = useState([]);
  const [stchannel_id, setChannel_id] = useState(1);

  const [menuwiseAds, setMenuwiseAds] = useState([]);
   useEffect(() => {
    getChannelFetchInfo(channel_seo_name, menuname);
  }, [menuname, channel_seo_name])

  const getChannelFetchInfo = async (channel_seo_name, menuname) => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {

        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourchannelInfoList(resp_datalist);
          getMenuInfo(menuname, resp_datalist[0].chnl_id);
          setChannel_id(resp_datalist[0].chnl_id);
         }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const getMenuInfo = async (menuname, chnl_id) => {
    const responseBody = { menuname: menuname };
    await axios
      .post(getmenudataseourl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setStoreMenu(resp_datalist);
          loadHeadings(resp_datalist[0].menu_cat_id, chnl_id);
          loadMenuhomewiseAds(resp_datalist[0].menu_cat_id, chnl_id);
          //loadPageAds(chnl_id);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const loadHeadings = async (menu_cat_id, chnl_id) => {
    //alert(chnl_id);
    const responseBody = { tag_menu_id: "", pageid: 1, channel_id: chnl_id };
    await axios
      .post(getloadheadingpage_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setOurHeadings(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };

  const loadMenuhomewiseAds = async (menu_cat_id, chnl_id) => {
    const responseBody = { menu_cat_name: menu_cat_id, channel_id: chnl_id };
    await axios
      .post(getloadmenuadsurl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setMenuwiseAds(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}

      ><div className='dskfmdskf'>

          <FaChevronRight className='sfdsf' /></div> </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><div className='fdsf'>
          < FaChevronLeft />
        </div>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    delay: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]

  };

  var settingsvertical = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    delay: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      }
    ]

  };
  //alert(JSON.stringify(ourhometags));
  // console.log(menuwiseAds);
  return (
    <div>
      <div className='maind'>
        <div className='daf'>
          <Container fluid className='pad-left-80 pad-right-80'>
          <div className='padin-t-b-10 d-block d-lg-none d-xl-none' >
              <div className='wid-500 text-center mobile-resp'>
                <Link to="#">
                  <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/1698379984KRISHNA_970x90_A.jpg" alt='' className='max-wid-100' height='100' />
                </Link>
              </div>
            </div>
            <Row>
              <Col lg={9}>
              
                {ourhometags &&
                  ourhometags.map(function (list, index) {
                    const tourl = "/heading/" + list.channel_seo_url + "/" + list.seo_tag_title;
                    if (list.count_videos > 0) {
                      if (list.tag_type == "show") {
                        return (

                          <div className="margin-b">


                            <>
                              <div class="row"><div class="col-lg-6 col-md-6 col-9"><h2 class="head">{list.tag_title} </h2>
                              <h4 className='styl-count'>{list.getVideosList.length}</h4>

                              </div><div class="text-end col-lg-6 col-md-6 col-3"><Link class="more" to={tourl} >More +</Link></div></div>


                              <div className='feature-main '>
                                <div className='featur'>

                                  <div className='fea-contact fea-arrow'>
                                    <Slider
                                      {...(list.layout_type === "horizontal" ? settings : settingsvertical)}
                                    >
                                      {list.getVideosList &&
                                        list.getVideosList.map(function (videolist, videoindex) {
                                          //  let suburl = "/"+videolist.channel_seo_url+"/"+list.menu_cat_name+"/"+videolist.show_name_seo_url;
                                          let suburl = "/" + videolist.channel_seo_url + "/showview/" + videolist.show_name_seo_url;

                                          return (<div >
                                            <div className='carousel-card extraicon'>
                                              <Link to={suburl}>
                                                {list.layout_type === "horizontal" ? (

                                                  <img src={videolist.show_image} alt='' />
                                                ) : (
                                                  <img src={videolist.show_image2} alt='' />
                                                )}

                                              </Link>


                                            </div>
                                          </div>)
                                        })}

                                    </Slider>
                                  </div>



                                </div>
                              </div>


                            </>
                          </div>

                        );
                      } else {
                        return (

                          <div key={list.tag_id}>

                            <>
                              <div class="row" ><div class="col-lg-6 col-md-6 col-9"><h2 class="head">{list.tag_title}</h2>
                              <h4 className='styl-count'>{list.getVideosList.length}</h4> 
                              </div><div class="text-end col-lg-6 col-md-6 col-3"><Link class="more" to={tourl}>More +</Link></div></div>


                              <div className='feature-main'>
                                <div className='feature'>
                                  <div className='fea-contact '>
                                    <Slider
                                      {...(list.layout_type === "horizontal" ? settings : settingsvertical)}
                                    >
                                      {list.getVideosList &&
                                        list.getVideosList.map(function (videolist, videoindex) {
                                          //  let tosuburl = "/video/"+videolist.channel_seo_url +"/"+ videolist.seo_tag_title+"/"+ videolist.sv_title_seo;
                                          let tosuburl = "/video/" + videolist.channel_seo_url + "/" + videolist.seo_tag_title + "/" + videolist.sv_title_seo;
                                      
                                          return ( 

                                            <div key={videoindex} >
                                              <div className='carousel-card extraicon'>
                                                <Link to={tosuburl} >
                                                  <div className='extra'>

                                          {videolist.custom_youtube_type === 'Youtube' ? (
                                          <img src={videolist.medium_img} alt='' />
                                          ) : (
                                          list.layout_type === 'horizontal' ? (
                                          <img src={videolist.image_path} alt='' />
                                          ) : (
                                          <img src={videolist.vertical_image} alt='' />
                                          )
                                          )}

{/* class="respon-img-width-heig" */}

                                                    <PlayIcon />
                                                  </div>
                                                  <h4>{videolist.sv_title}</h4>
                                                </Link>

                                              </div>
                                            </div>

                                          );

                                        })}
                                    </Slider>

                                  </div>

                                </div>
                              </div>


                            </>
                          </div>

                        );

                      }
                    }

                  })}




                {/* <Feature name="Popular videos" />
                <Videomoreplus name="CASH" />
                <Videomoreplus name="PADUTHA THEEYAGA SERIES 16" />
                <Videomoreplus name="dhee jodi" /> */}


              </Col>

              <Col lg={3} className='d-lg-block d-xl-block'>
                <div>
                  <Hotsummer mymenuads={menuwiseAds} channel_id={stchannel_id} page={1} />
                </div>



              </Col>
            </Row>


          </Container>
        </div>



      </div>
    </div>
  )
}

export default Videoblockmain