import React , { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { headeradurl } from "../../../globalURL";
//import { useGooglePublisherTag } from 'react-google-publisher-tag';
import { Gpt } from "react-gpt-ads"


function HeaderAdd() {

  useEffect(() => {
    getHeaderAd();
   
  }, [])
  const [ourAds, ourSetAds] = useState([])
  const getHeaderAd = async () => {
    const responseBody = { };
    await axios
      .post(headeradurl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourSetAds(resp_datalist);
        }
      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  if(ourAds.length > 0){
  
   

 // "ad_size":"\"[728, 90]\""
    if(ourAds[0].type == "Imageupload"){
      return (

        <>
            <Link target="_blank" to={ourAds[0].redirection_link} ><img src={ourAds[0].image_url}  className='w-100' /></Link>
        </>
      )
    }else{
      //const parsedAdsize = JSON.parse(JSON.parse(ourAds[0].ad_size));
    var parseUnit = ""; if(ourAds[0]?.scriptads!=""){ parseUnit = JSON.parse(ourAds[0]?.scriptads); }
       const adssizes = [[728, 90], [970, 90],  [468, 60], [300, 50],];
      return (
        <div>
        <Gpt 
            adUnit={parseUnit}
            name="multi-size-ad"
            size={adssizes}
        />

    </div>
    
      )
    }
}
}

export default HeaderAdd