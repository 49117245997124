import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Feature from '../../videoblock/Components/Feature'
import { Link, json, useLocation, useParams } from 'react-router-dom'
import vide from "./Video/video.mp4"
import Hotsummer from '../../videoblock/Hotsummer'
import AddBanner from './AddBanners/AddBanner'
import AddSlider from './AddSlider'
import VideoView from './VideoView'
import { pagewiseads_url,getSeasonShowVideos_url,getloadheading_url,get_seasons_by_show_url, getshow_url, showsvideoslist_url, getloadheadingpage_url, getchannelbyseoname, getmenudataseourl, getshowseourl, getloadmenuadsurl, menuwiseshowadsurl } from "../../../globalURL";
import axios from 'axios';
import PlayIcon from '../../PlayIcon';
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight, } from 'react-icons/fa'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./ShowVideoView.css"
import Dropdown from '../../Dropdown'
import Episodes from '../../Episodes'
import Videotabs from '../../Videotabs';
const ShowVideoView = (props) => {
  var showid = 0;
  var chnl_id = 0;
  var menu_cat_id = 0;
  const params = useParams();
  const channel_seo_name = params.channelseo;
  const menuname = params.menuname;
  const showseo = params.showseo;
  const [ourShows, ourShowsList] = useState([]);
  const [ourshowtags, setOurShowHeadings] = useState([])
  const [storechannel, setStorechannel] = useState([]);
  const [storeMenu, setStoreMenu] = useState([]);
  const [storeShow, setStoreShow] = useState([]);
  const [menuwiseAdsfirst, setMenuwiseAdsfirst] = useState([]);
  const [menuwiseAdslast, setMenuwiseAdslast] = useState([]);
  const [menushowwiseAds, setmenushowwiseAds] = useState([]);
  const [ourrelatedvideos, ourSetRelatedVideos] = useState([]);
  const [ourheadinginfo, setOurheadinginfo] = useState([]);
  const [ourSeasonsList, ourSetSeasonsList] = useState([]);
  const [ourSeasonsVideoList, ourSetSeasonsVideoList] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [ourSeasonsinfo, setOurSeasonsinfo] = useState({});

  var sidetagid=0;
  useEffect(() => {

    getChannelInfo(channel_seo_name, menuname, showseo);
    // getMenuInfo(channel_seo_name,menuname,showseo);
    // getShowInfo(channel_seo_name,menuname,showseo);
    // Find the selected tab by class name
    // const selectedTab = document.querySelector('.react-tabs__tab--selected');
    
    // // Check if the selected tab element exists before triggering a click event
    // if (selectedTab) {
    //   selectedTab.click();
    // }
  }, [channel_seo_name, menuname, showseo])
  



  const getChannelInfo = async (channel_seo_name, menuname, showseo) => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setStorechannel(resp_datalist);
          getShowInfo(resp_datalist[0].chnl_id, showseo);
          loadHeadings(resp_datalist[0].chnl_id);
          loadMenuShowwiseAds(resp_datalist[0].chnl_id);
          loadMenuhomewiseAds(resp_datalist[0].chnl_id);
          loadMenuhomewiseAdsLast(resp_datalist[0].chnl_id);
          getMenuInfo(resp_datalist[0].chnl_id, menuname, showseo);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }

  const getMenuInfo = async (chnl_id, menuname, showseo) => {
    const responseBody = { menuname: menuname };
//console.log(responseBody);
    await axios
      .post(getmenudataseourl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setStoreMenu(resp_datalist);
        
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }


  const getShowInfo = async (chnl_id, showseo) => {
    const responseBody = { showseoname: showseo,show_channel_id:chnl_id };
    await axios
      .post(getshowseourl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setStoreShow(resp_datalist);

        //  ourSetSeasonsVideoList([]);
          ourSetSeasonsVideoList([]);
          setOurSeasonsinfo({});
          fetchShows(resp_datalist[0].show_id, chnl_id);
          fetchSeasons(resp_datalist[0].show_id);
          fetchRelatedVideos(resp_datalist[0].show_id, chnl_id)
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }


  const fetchRelatedVideos = async (showid, chnl_id) => {
    // const session_chnl_id = localStorage.getItem('session_chnl_id');
    const responseBody = { channel_id: chnl_id, show_id: showid, typevideo: "Episode" };
    await axios
      .post(showsvideoslist_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourSetRelatedVideos(resp_datalist);
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };


  const fetchSeasons = async (showid) => {
    // const session_chnl_id = localStorage.getItem('session_chnl_id');
    const responseBody = { show_id: showid };
    await axios
      .post(get_seasons_by_show_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          if(resp_datalist.length > 0){
            funSeasonResults(showid,resp_datalist[0].id);
          }
          ourSetSeasonsList(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };


  const fetchShows = async (showid, chnl_id) => {
    // const session_chnl_id = localStorage.getItem('session_chnl_id');
    const responseBody = { chnl_id: chnl_id, show_id: showid };
    await axios
      .post(getshow_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
      
          ourShowsList(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };


  const loadHeadings = async (chnl_id) => {

    const responseBody = { tag_menu_id: "", pageid: 2, channel_id: chnl_id };

    await axios
      .post(getloadheadingpage_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {

        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setOurShowHeadings(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
  const loadMenuhomewiseAds = async (chnl_id, menu_cat_id, position) => {
   // const responseBody = { menu_cat_name: menu_cat_id, channel_id: chnl_id, position: position };
   if(chnl_id == 5 || chnl_id == 7){ chnl_id = 1;}
   const responseBody = { channel_id: chnl_id,page:2,position:1 };
//alert(JSON.stringify(responseBody));
    await axios
      .post(pagewiseads_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setMenuwiseAdsfirst(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
  const loadMenuhomewiseAdsLast = async (chnl_id, menu_cat_id, position) => {
  //  const responseBody = { menu_cat_name: menu_cat_id, channel_id: chnl_id, position: position };
  if(chnl_id == 5 || chnl_id == 7){ chnl_id = 1;}
  const responseBody = { channel_id: chnl_id,page:2,position: 2 };
 
  await axios
      .post(pagewiseads_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setMenuwiseAdslast(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
  const loadMenuShowwiseAds = async (chnl_id) => {
    //const responseBody = { tag_menu_id: menu_cat_id, tag_channel_id: chnl_id };
    const responseBody = { tag_channel_id: chnl_id, page_id: 3 };
    await axios
      .post(menuwiseshowadsurl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data; var ourshows = [];
          if(resp_datalist.length > 0){
           // console.log(resp_datalist);
            var ourshows = resp_datalist[0].ourShows;
       
            setmenushowwiseAds(ourshows);
            setOurheadinginfo(resp_datalist);
         

          }
       //   var showdata = resp_datalist.data;


        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
  const funSeasonResults = async (show_id,seasons_id) => {
     //const responseBody = { tag_menu_id: menu_cat_id, tag_channel_id: chnl_id };
    const responseBody = { show_id: show_id, seasons_id: seasons_id };
  //alert(JSON.stringify(responseBody));
    await axios
      .post(getSeasonShowVideos_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;        
        if (resp_data.status === 'true') {
          var resp_datalist = resp_data.data; var ourshows = [];
          setOurSeasonsinfo(resp_data.season_info);
          ourSetSeasonsVideoList(resp_datalist);


        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };

  //design changes 
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}

      ><div className='dskfmdskf'>

          <FaChevronRight className='sfdsf' /></div> </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><div className='fdsf'>
          < FaChevronLeft />
        </div>
      </div>
    );
  }
  console.log("ourSeasonsVideoList",ourSeasonsVideoList);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    delay: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]


  };

  var settingsvideos = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    delay: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      }
    ]


  };
  //end design changes
  if(ourheadinginfo.length > 0 ){
    sidetagid = ourheadinginfo[0].tag_id;
  }
 

  if (ourShows.length > 0) {

    var htmlString = ourSeasonsinfo?.season_desc?(ourSeasonsinfo?.season_desc):( ourShows[0].show_desc) ;
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };
  
    const maxCharacters = 500;
    let  truncatedText = "";
    let  showToggle = 0;
    if(htmlString.length > 0){

      truncatedText = isExpanded ? htmlString : htmlString.slice(0, maxCharacters);
      showToggle = htmlString.length > maxCharacters; 
    }

    return (
      <div>
        <Container fluid className='pad-left-80 pad-right-80'>
          
          <Row>
            <Col lg={9}>
              <>


                <div className='backcolo'>
                  <div className='videod'>
                    {ourSeasonsinfo?.season_banner?(   <img src={ourSeasonsinfo?.season_banner} />):(   <img src={ourShows[0].show_banner} />)}
                 
                      
                  </div>
                  <div>   
                  <Tabs defaultIndex={0} >
  <div className=''>
    <Container>
      <Row className='shoed'>
        <Col lg={12} md={12} sm={12} xs={12}> 
          <TabList className="title all seacon">
            {ourSeasonsList && ourSeasonsList.map(function (seasonlist, seasonindex) {
              return (
                <Tab onClick={() => funSeasonResults(seasonlist.show_id,seasonlist.id)} key={seasonindex}>
                  {seasonlist.season_name}
                </Tab>
              );
            })}
          </TabList>
        </Col>
      </Row>
    </Container>
  </div>

  <div className='colof'>
    <Container>
      {/* Content for the selected tab goes here */}
    </Container>
  </div> 
</Tabs>

</div>
                  <Row className="sara">
                    <Col lg={7} md={6} sm={6} xs={12}>



                      <h1 className='ali'>{ourShows[0].show_name}</h1>
                      <div className="showtime">

                        <p className='shiw'> TV SHOW</p>
                        <span>{ourShows[0].tv_show_timings}</span>
                    
                        

                      </div>

                    </Col>
                    <Col lg={5} md={6} sm={6} xs={12}>
                    {ourShows[0].show_website_url !== "" && (
                      <div className='watch'>
                        <div className='watch-in'>
                          <Row className='edio'>
                            <Col lg={5} md={6} sm={6} xs={6}>
                              <p>Watch Full <br /><span>Content on</span></p>
                            </Col>
                            <Col lg={7} md={6} sm={6} xs={6}>
                              <Row>
                                <Col lg={4} md={4} sm={4} xs={6} className="telugu">
                                  <div className='etv-telugu'><img src="../../../assets/images/etv-win.png" width="30"></img></div>
                                </Col>
                                <Col lg={8} md={8} sm={8} xs={6} className="telugu">
                                  <Link to={ourShows[0].show_website_url} target='_blank' class="clic">Click Here</Link>
                                </Col>
                              </Row>

                            </Col>
                          </Row>


                        </div>
                      </div>
                   )   }
                    </Col>
                  </Row>
                  {ourShows[0].show_desc.length > 0 && 
                  <div className='backcolo-in'>
                    <div className='des'>
              
                    <p style={{ margin: "0px" }}>
{isExpanded ? (
<div dangerouslySetInnerHTML={{ __html: htmlString }} />
) : (
<div dangerouslySetInnerHTML={{ __html: truncatedText + (showToggle ? "<b>...</b>" : "") }} />
)}
{showToggle && (
<button onClick={toggleExpand} className='syle-color' >
{isExpanded ? 'Read Less' : 'Read More'}
</button>
)}
</p>



                    </div>
                  </div>
  }
                </div>

              </>
              <div className='padin-t-b-10 d-block d-lg-none d-xl-none' >
  <div className='wid-500 text-center mobile-resp'>
    <Link to="#">
      <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/1698379984KRISHNA_970x90_A.jpg" alt='' className='max-wid-100' height='100' />
    </Link>
  </div>
</div>
              <Episodes ourSeasonsVideoList = {ourSeasonsVideoList} />
              {ourShows[0].isrelatedvideos == 1 && ourrelatedvideos.length > 0 && (

                <div >


                  <>
                  
                    <div class="row" ><div class="col-lg-6 col-md-6 col-9"><h2 class="head">Related Videos</h2></div><div class="text-end col-lg-6 col-md-6 col-3"></div></div>


                    <div className='feature-main'>
                      <div className='feature'>
                        <div className='fea-contact fea-arrow'>
                          <Slider {...settings}>
                            {ourrelatedvideos &&
                              ourrelatedvideos.map(function (videolist, videoindex) {
                                let tosuburl = "/video/" + videolist.channel_seo_url + "/" + videolist.show_name_seo_url + "/" + videolist.sv_title_seo;
                                // alert(JSON.stringify(videolist))
                                return (

                                  <div key={videoindex} >
                                    <div className='carousel-card extraicon'>
                                      <Link to={tosuburl} >
                                        <div className='extra'>
                                          {/* <img src={videolist.image_path} alt='' /> */}
                                          {videolist.custom_youtube_type === 'Youtube' ? (
                                          <img src={videolist.medium_img} alt='' />
                                          ) : (
                                            <img src={videolist.image_path} alt='' />
                                          )}
                                          <PlayIcon />
                                        </div>


                                        <h4>{videolist.sv_title}</h4>
                                      </Link>

                                    </div>
                                  </div>

                                );

                              })}
                          </Slider>

                        </div>

                      </div>
                    </div>


                  </>
                </div>
              )}


              {/* {ourshowtags &&
          ourshowtags.map(function (list, index) {
          if(list.count_videos >0){
            const tourl = "/heading/"+list.channel_seo_url+"/"+list.seo_tag_title;

            return (
            
              <div key={list.tag_id}>


                <>
                  <div class="row" ><div class="col-lg-6 col-md-6 col-9"><h2 class="head">{list.tag_title}</h2></div><div class="text-end col-lg-6 col-md-6 col-3"><Link class="more" to={tourl}>More +</Link></div></div>


                  <div className='feature-main'>
                    <div className='feature'>
                      <div className='fea-contact '>
                      <Slider {...settings}>
                          {list.getVideosList &&
                            list.getVideosList.map(function (videolist, videoindex) {
                              let tosuburl = "/video/"+videolist.channel_seo_url +"/"+ videolist.seo_tag_title+"/"+ videolist.sv_title_seo;
                              
                              return (
                               
                                <div key={videoindex} >
                                  <div className='carousel-card extraicon'>
                                    <Link to={tosuburl} >
                                      <div className='extra'>
                                        <img src={videolist.image_path} alt='' />
                                        <PlayIcon />
                                      </div>


                                      <h4>{videolist.sv_title}</h4>
                                    </Link>

                                  </div>
                                </div>
                            
                              );

                            })}
                                </Slider>

                        </div>
                   
                    </div>
                  </div>


                </>
              </div>
            
            );
                          }

          })}
              */}

       
              {ourshowtags &&
                ourshowtags.map(function (list, index) {
                  const tourl = "/heading/" + list.channel_seo_url + "/" + list.seo_tag_title;
                  if (list.count_videos > 0) {
                    
                    if(sidetagid != list.tag_id){
                      if (list.tag_type == "show") {
                        return (

                          <div className='margin-b'>


                            <>
                              <div class="row"><div class="col-lg-6 col-md-6 col-9"><h2 class="head">{list.tag_title}</h2></div><div class="text-end col-lg-6 col-md-6 col-3"><Link class="more" to={tourl} >More +</Link></div></div>

                            
                              <div className='feature-main'>
                                <div className='featur'>
                                  <div className='fea-contact'>
                                    <Slider
                                      {...(list.layout_type === "horizontal" ? settings : settingsvideos)}
                                    >
                                      {list.getVideosList &&
                                        list.getVideosList.map(function (videolist, videoindex) {
                                          //  let suburl = "/"+videolist.channel_seo_url+"/"+list.menu_cat_name+"/"+videolist.show_name_seo_url;
                                          let suburl = "/" + videolist.channel_seo_url + "/showview/" + videolist.show_name_seo_url;
                                          return (<div >
                                            <div className='carousel-card extraicon'>
                                              <Link to={suburl}>
                                                {list.layout_type === "horizontal" ? (
                                                  <img src={videolist.show_image} alt='' />
                                                ) : (
                                                  <img src={videolist.show_image2} alt='' />
                                                )}

                                              </Link>


                                            </div>
                                          </div>)
                                        })}

                                    </Slider>
                                  </div>
                                </div>
                              </div>


                            </>
                          </div>

                        );
                      } else {
                        return (

                          <div key={list.tag_id}>

                            <>
                              <div class="row" >
                                <div class="col-lg-6 col-md-6 col-9">
                                  <h2 class="head" style={{display:"inline-block"}}>{list.tag_title}  </h2>
                                  <div className='styl-count'>{list.getVideosList.length}</div>
                                  </div>
                                  <div class="text-end col-lg-6 col-md-6 col-3"><Link class="more" to={tourl}>More +</Link>
                                  </div>
                                </div>


                              <div className='feature-main'>
                                <div className='feature'>
                                  <div className='fea-contact '>

                                    <Slider
                                      {...(list.layout_type === "horizontal" ? settings : settingsvideos)}
                                    >



                                      {list.getVideosList &&
                                        list.getVideosList.map(function (videolist, videoindex) {
                                          //  let tosuburl = "/video/"+videolist.channel_seo_url +"/"+ videolist.seo_tag_title+"/"+ videolist.sv_title_seo;
                                          let tosuburl = "/video/" + videolist.channel_seo_url + "/" + videolist.seo_tag_title + "/" + videolist.sv_title_seo;

                                          return (

                                            <div key={videoindex} >
                                              <div className='carousel-card extraicon'>
                                                <Link to={tosuburl} >
                                                  <div className='extra'>
                                                  {videolist.custom_youtube_type === 'Youtube' ? (
                                          <img src={videolist.medium_img} alt='' />
                                          ) : (
                                          list.layout_type === 'horizontal' ? (
                                          <img src={videolist.image_path} alt='' />
                                          ) : (
                                          <img src={videolist.vertical_image} alt='' />
                                          )
                                          )}
                                                    {/* {list.layout_type === "horizontal" ? (
                                                      <img src={videolist.image_path} alt='' />
                                                    ) : (
                                                      <img src={videolist.vertical_image} alt='' />
                                                    )} */}

                                                    <PlayIcon />
                                                  </div>
                                                  <h4>{videolist.sv_title}</h4>
                                                </Link>

                                              </div>
                                            </div>

                                          );

                                        })}
                                    </Slider>

                                  </div>

                                </div>
                              </div>


                            </>
                          </div>

                        );

                      }
                  }
                  }

                })}
            </Col>
            <Col lg={3}>
              <div>
                <AddBanner firstads={menuwiseAdsfirst} ourheadinginfo = {ourheadinginfo} lastads={menuwiseAdslast} secondads={menushowwiseAds} />
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    )
  }
}

export default ShowVideoView