import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import addimg from './header-add.png';
import { pagewiseads_url, getchannelbyseoname } from '../../globalURL';
import axios from 'axios';

const NewAddsPage = (props) => {
  const { channel_seo_name, position } = props;
  const [pageFData, setPageFData] = useState([]);

  useEffect(() => {
    getChannelInfo(channel_seo_name);
  }, [channel_seo_name, position]);

  const getChannelInfo = async () => {
    try {
      const responseBody = { chnl_seo_name: channel_seo_name };
      const res = await axios.post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { 'Content-Type': 'application/json' },
      });

      const resp_data = res.data;
      if (resp_data.status === 'true' && resp_data.data != null) {
        const resp_datalist = resp_data.data;
        const chnl_id = resp_datalist[0].chnl_id;
        loadPageAds(chnl_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadPageAds = async (chnl_id) => {
    try {
      const responseBody = { channel_id: chnl_id, page: 4, position: position };
      const res = await axios.post(pagewiseads_url, JSON.stringify(responseBody), {
        headers: { 'Content-Type': 'application/json' },
      });

      const resp_data = res.data;
      if (resp_data.status === 'true' && resp_data.data != null) {
        const resp_datalist = resp_data.data;
        setPageFData(resp_datalist);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {pageFData.map((ads, index) => {
        if (ads.ad_type === 'Imageupload') {
          return (
            <div className='padin-t-b-10' key={index}>
              <div className='wid-500 text-center mobile-resp'>
                <Link to={ads.redirection_link}>
                  <img src={ads.image_url} alt='' className='max-wid-100' height='100' />
                </Link>
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default NewAddsPage;
