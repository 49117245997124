import React, { useState, useEffect } from 'react'
import { Col, Container, DropdownButton, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import Hotsummer from '../videoblock/Hotsummer'
import TrendingNews from './TrendingNews'
import { pagewiseads_url,getHeadingsBypagenews_url, getnewsHeadingsDetailed_url,getchannelbyseoname, getnewsHeadingsseo_url, getvideoseo, getmenudataseourl, getloadmenuadsurl } from "../../globalURL";
import axios from 'axios';
import PlayIcon from '../PlayIcon'
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight, } from 'react-icons/fa'
import Videotabs from '../Videotabs'
import Episodes from '../Episodes'
import add1 from "./add-01.png"
import add2 from "./add-02.png"
import add3 from "./add.png"
import NewsRelated from './NewsRelated'

export const NewsView = (props) => {



  // const toggleExpansion = () => {
  //   setIsExpanded(!isExpanded);
  // };
  const params = useParams();
  const seo_tag_title = params.name;
  const channel_seo_name = params.channelseo;
  const video_title = params.videotitle;
  const menuname = params.menuname;
  const [ourvideo, setOurVideo] = useState([]);
  const [ourrelatednews, setOurrelatednews] = useState([]);
  const [storeMenu, setStoreMenu] = useState([]);
  const [menuwiseAds, setMenuwiseAds] = useState([]);
  const [ourhometags, setOurHeadings] = useState([]);
  const [newsDetailsHeadings, setNewDetailsHeadings] = useState([])
   const [isExpanded, setIsExpanded] = useState(false);
   const [stchannel_id, setChannel_id] = useState(1);
   const [pageFData, setPageFData] = useState([]);
   const [pageSData, setPageSData] = useState([]);
   const [pageTData, setPageTData] = useState([]);
  const [showToggle, setShowToggle] = useState(false);
  useEffect(() => {
    
    getChannelInfo(channel_seo_name, menuname,seo_tag_title,video_title);
    
    // getTagsInfo();
  }, [channel_seo_name, menuname,seo_tag_title,video_title])
  const getChannelInfo = async (channel_seo_name,menuname,seo_tag_title,video_title) => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {

        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          var channel_id = resp_datalist[0].chnl_id;
          getTagsInfo(channel_id, seo_tag_title,video_title);
          getMenuInfo(menuname, resp_datalist[0].chnl_id);
          setChannel_id(resp_datalist[0].chnl_id);
          loadPageAds(resp_datalist[0].chnl_id);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const loadPageAds = async (chnl_id) => {
    //  const responseBody = { menu_cat_name: menu_cat_id, channel_id: chnl_id };
    const responseBody = { channel_id: chnl_id,page:5,position:4};
      await axios
        .post(pagewiseads_url, JSON.stringify(responseBody), {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          var resp_data = res.data;
          if (resp_data.status === 'true' && resp_data != null) {
            var resp_datalist = resp_data.data;
            setPageFData(resp_datalist);
          }
  
        })
        .catch((error) => {
          //setIsLoading(false);
          console.log(error);
        });


        const responseBodys = { channel_id: chnl_id,page:5,position:5 };
        await axios
          .post(pagewiseads_url, JSON.stringify(responseBodys), {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            var resp_data = res.data;
            if (resp_data.status === 'true' && resp_data != null) {
              var resp_datalist = resp_data.data;
              setPageSData(resp_datalist);
            }
    
          })
          .catch((error) => {
            //setIsLoading(false);
            console.log(error);
          });


          const responseBodyT = { channel_id: chnl_id,page:5,position:6 };
          await axios
            .post(pagewiseads_url, JSON.stringify(responseBodyT), {
              headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
              var resp_data = res.data;
              if (resp_data.status === 'true' && resp_data != null) {
                var resp_datalist = resp_data.data;
                setPageTData(resp_datalist);
              }
      
            })
            .catch((error) => {
              //setIsLoading(false);
              console.log(error);
            });

    };
  const getMenuInfo = async (menuname, chnl_id) => {
    const responseBody = { menuname: menuname };
    await axios
      .post(getmenudataseourl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setStoreMenu(resp_datalist);
          loadMenuhomewiseAds(resp_datalist[0].menu_cat_id, chnl_id);
     
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const loadMenuhomewiseAds = async (menu_cat_id, chnl_id) => {
    const responseBody = { menu_cat_name: menu_cat_id, channel_id: chnl_id };
    await axios
      .post(getloadmenuadsurl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setMenuwiseAds(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
  const getTagsInfo = async (channel_id, seo_tag_title,video_title) => {
    const responseBody = { channel_id: channel_id, seo_tag_title: seo_tag_title };
    await axios
      .post(getnewsHeadingsseo_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {

        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setOurrelatednews(resp_datalist);
          var tag_id = resp_datalist[0].tag_id;
          getTagsVideoInfo(channel_id, tag_id, video_title);
          loadHeadings(channel_id);
          loadHeadingsDetailed(channel_id,tag_id);

        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }



  const getTagsVideoInfo = async (channel_id, tag_id, video_title) => {
    const responseBody = { channel_id: channel_id, tag_id: tag_id, video_title: video_title };
    await axios
      .post(getvideoseo, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data; console.log(resp_data);
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          //  console.log("fwwerwer");
          setOurVideo(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }

  const loadHeadings = async (chnl_id) => {
    //alert(chnl_id);
    const responseBody = { tag_menu_id: "", pageid: 1, channel_id: chnl_id };
    await axios
      .post(getHeadingsBypagenews_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setOurHeadings(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
  const loadHeadingsDetailed = async ( chnl_id,clickedtagid) => {
    const responseBody = { pageid: 3, channel_id: chnl_id };
    await axios
      .post(getnewsHeadingsDetailed_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        console.log()
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
              var resp_datalist = resp_data.data;
              var arrayNewsData = [];

              if (resp_datalist.length > 0) {
              resp_datalist.forEach((tags, index) => {
              if (tags.tag_id != clickedtagid) {
              arrayNewsData.push(tags); 
              }
              });
              }

              setNewDetailsHeadings(arrayNewsData);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
  //design related 
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}

      ><div className='dskfmdskf'>

          <FaChevronRight className='sfdsf' /></div> </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><div className='fdsf'>
          < FaChevronLeft />
        </div>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    delay: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]


  };

  var settingsvertical = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    delay: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]

  };
//   console.log("rajiiiiiii");
 //console.log(ourhometags);

// console.log("rajiiiiiii");
  //end design related
  if (ourvideo.length > 0) {
  
    var htmlString = ourvideo[0].video_desc;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const maxCharacters = 500;
  let  truncatedText = "";
  let  showToggle = 0;
  if(htmlString.length > 0){

    truncatedText = isExpanded ? htmlString : htmlString.slice(0, maxCharacters);
    showToggle = htmlString.length > maxCharacters; 
  }
  const tonewsurl = ourrelatednews.length > 0 ? `/heading/${channel_seo_name}/${ourrelatednews[0].seo_tag_title}` : '';

    return (
      <>
        <div className='pageSpace addban top'>
        <Container fluid className='pad-left-80 pad-right-80'>
            <Row>
              <Col lg={9}>


                <div className='backcolo'>
                  <div className='video-container'>

                    <iframe width="100%" height="" src={`https://www.youtube.com/embed/${ourvideo[0].sv_youtube_link}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>


                    {/* <img src="../assets/images/news-01.jpg" alt='' className='w-100'/> */}
                        
                  </div>
              

                  <Row className="sara">
                    <Col lg={12} md={12} sm={12} xs={12}>



                      <h1 className='ali mb-0 '>{ourvideo[0].sv_title}</h1>
                      {/* <div class="showtime">
            <span>24 April 2023, 08:00 PM</span>

            </div> */}

                    </Col>

                  </Row>
                  {ourvideo[0].video_desc.length > 0 &&  <div className='backcolo-in'>
                    <div className='des'>
                      <h6 className='time-class-style'>Updated:{ourvideo[0].datedisplay}</h6>
                      {/* <h4>Description</h4> */}
                      {/* <p style={{margin:"0px"}}>   <div className='text-line-limit-3' dangerouslySetInnerHTML={{ __html: htmlString }} />{isExpanded ? <span></span> : null }</p> */}




                
                      {/* <Button>Read More</Button> */}

                      {/* {isExpanded ? <p onClick={toggleExpansion} className='syle-color'>Read Less</p> : <p onClick={toggleExpansion} className='syle-color'>Read More....</p>} */}

                      <p style={{ margin: "0px" }}>
                      {isExpanded ? (
  <div dangerouslySetInnerHTML={{ __html: htmlString }} />
) : (
  <div dangerouslySetInnerHTML={{ __html: truncatedText + (showToggle ? "<b>...</b>" : "") }} />
)}

        {/* {showToggle && (
          <button onClick={toggleExpand} className='syle-color' >
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
        )} */}
{showToggle && (
        <>
          <button onClick={toggleExpand} className='style-color'>
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
        </>
      )}


      </p>

                    </div>
                  </div>
  }
                  <div className='wid-202'>
           
            </div>

                </div>
         
              </Col>
              <Col lg={3}  className='d-lg-block d-xl-block sum'>
              {pageFData && pageFData.map(function (ads, videoindex) {
    
    if(ads.ad_type == "Imageupload"){
     return (
    <Link to={ads.redirection_link}  target='_blank' className='mb-3 d-block'>
      <img src={ads.image_url} alt=""/> 
    </Link>
     )
    }
})}
 {pageSData && pageSData.map(function (ads, videoindex) {

if(ads.ad_type == "Imageupload"){
return (
<Link to={ads.redirection_link}  target='_blank' className='mb-3 d-none d-lg-block d-xl-block'>
<img src={ads.image_url} alt=""/> 
</Link>
)
}
})}
{pageTData && pageTData.map(function (ads, videoindex) {

if(ads.ad_type == "Imageupload"){
return (
<Link to={ads.redirection_link}  target='_blank' className='mb-3 d-block'>
<img src={ads.image_url} alt=""/> 
</Link>
)
}
})}
                {/* <a target='_blank' href="https://www.etvwin.com/win-exclusive/dil-se">
  <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/ads1697456610WhatsApp-Image-2023-10-16-at-17_07_52.jpeg" class="addonestyl" />
</a>

<a  target='_blank' href="https://www.etvwin.com/shows/suma-adda/season-1-10/21-october-23-latest-promo">
  <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/1697632291WhatsApp-Image-2023-10-18-at-17_12_25.jpeg" class="addonestyl" />
</a>

<a target='_blank' href="https://www.etvwin.com/win-exclusive/mr-nagabhushanam">
  <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/ads1697456642WhatsApp-Image-2023-10-16-at-17_07_52-(1).jpeg" class="addtwostyl3" />
</a> */}

                {/* <img src={add2} className='addtwostyl2'/> */}
                
                {/* <img  src={add3} className='addtwostyl3'/> */}
        

              </Col>
            </Row>
            
          </Container>
          <Container fluid className='pad-left-80 pad-right-80'>
                   {/* <TrendingNews myrelatednews = {ourrelatednews}  videoid={ourvideo[0].sv_id} channel_seo_name={channel_seo_name} /> */}

                
                   {ourrelatednews.length > 0  && (


<div >


  <>
    <div class="row" ><div class="col-lg-6 col-md-6 col-9"><h2 class="head">Related {ourrelatednews[0].seo_tag_title } </h2></div><div class="text-end col-lg-6 col-md-6 col-3"><Link class="more" to={tonewsurl}>More +</Link></div></div>


    <div className='feature-main'>
      <div className='feature'>
        <div className='fea-contact '>
          <Slider {...settings}>



            {ourrelatednews[0].video_list &&
              ourrelatednews[0].video_list.map(function (videolist, videoindex) {
              //  alert(JSON.stringify(videolist));
             // /NewsView/:channel_seo/:menuname/:name/:videotitle
             //console.log(ourvideo[0].sv_id);
                let tosuburl = "/NewsView/" + channel_seo_name + "/"  + ourrelatednews[0].menu_cat_name + "/"+ ourrelatednews[0].seo_tag_title + "/" + videolist.sv_title_seo;
                if (videolist.sv_id != ourvideo[0].sv_id) {
                  return (

                    <div key={videoindex} >
                      <div className='carousel-card extraicon'>
                        <Link to={tosuburl} >
                          <div className='extra'>
                            <img src={videolist.medium_img} alt='' />
                            <PlayIcon />
                          </div>


                          <h4>{videolist.sv_title}</h4>
                        </Link>

                      </div>
                    </div>

                  );
                }

              })}
          </Slider>

        </div>

      </div>
    </div>


  </>
</div>
)}
       <div className='padin-t-b-10 d-block d-lg-none d-xl-none' >
  <div className='wid-500 text-center mobile-resp'>
    <Link to="#">
      <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/1698379984KRISHNA_970x90_A.jpg" alt='' className='max-wid-100' height='100' />
    </Link>
  </div>
</div>        
            <NewsRelated detailedpage = {newsDetailsHeadings}   otherchanneltagdetails ={ourhometags} />
      
          </Container>

      
        </div>
      </>

    )
  }
}
